const w = 0;
const animSpeed = 300;

$(document).ready(function() {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(Flip);

  // Hero section animation
  const introFadeIn = new TimelineMax();

  introFadeIn
    .to($('.hero-video'), 1, { opacity: 1, ease: Linear.easeNone }, '+=.4')
    .to($('.hero-wrapper h1'), 1, { opacity: 1, ease: Linear.easeNone }, '+=0')
    .to($('.hero-wrapper .subtitle'), 1, { opacity: 1, ease: Linear.easeNone }, '-=.8')
    .to($('.hero-wrapper .info'), 1, { opacity: 1, ease: Linear.easeNone }, '-=.3')
    .to($('.scroll'), .6, { opacity: 1, ease: Linear.easeNone }, '+=0')
    .to($('.scroll-arrow'), .4, { y: 5, ease: Power1.easeInOut }, '+=0')
    .to($('.scroll-arrow'), .4, { y: -5, ease: Power1.easeInOut }, '+=0')
    .to($('.scroll-arrow'), .4, { y: 5, ease: Power1.easeInOut }, '+=0')
    .to($('.scroll-arrow'), .4, { y: -5, ease: Power1.easeInOut }, '+=0')
    .to($('.scroll-arrow'), .4, { y: 5, ease: Power1.easeInOut }, '+=0')
    .to($('.scroll-arrow'), .4, { y: 0, ease: Power1.easeInOut }, '+=0');

  // Hero section parallax
  const heroTl = gsap.timeline({
    scrollTrigger: {
      trigger: '.hero-block',
      start: 'top 150px',
      end: 'bottom top',
      scrub: true
    }
  });

  heroTl
    .to($('.hero-video'), 1, { scale: 1.2, y: -170, ease: Power1.easeOut }, '+=0')
    .to($('.hero-wrapper h1'), 1, { y: -400, ease: Power1.easeInOut }, '+=0')
    .to($('.hero-wrapper .subtitle'), 1, { y: -300, ease: Power1.easeInOut }, '-=1')
    .to($('.hero-wrapper .info'), 1, { y: -200, ease: Power1.easeInOut }, '-=1')
    .to($('.scroll'), 1, { y: -200, ease: Power1.easeInOut }, '-=1');

  $('.quote-wrapper').each((i, el) => {
    const quoteTl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        start: 'top 95%',
      },
    });

    if (el.classList.contains('has-author-photo')) {
      quoteTl
        .to(el.querySelector('.author'), .8, { scale: 1, opacity: 1, ease: Power1.easeInOut }, '+=0')
        .to(el.querySelector('p'), .8, { scale: 1, opacity: 1, ease: Power1.easeInOut }, '-=.4')
        .to(el.querySelector('.citation'), .8, { y: 0, opacity: 1, ease: Power1.easeInOut }, '-=.4');
    } else {
      quoteTl
        .to(el.querySelector('p'), .8, { scale: 1, opacity: 1, ease: Power1.easeInOut }, '+=0')
        .to(el.querySelector('.citation'), .8, { y: 0, opacity: 1, ease: Power1.easeInOut }, '-=.4');
    }
  });

  // Pause hero background video when off screen
  const heroBgVid = $('#video_bg')[0];

  function playHeroVid() {
    heroBgVid.play();
  }

  ScrollTrigger.create({
    trigger: '#hero',
    start: 'bottom top',
    onEnterBack: playHeroVid,
  })

  // Paragraph animation
  const contentBlock = gsap.utils.toArray('.content-block p');

  contentBlock.forEach((block, i) => {
    gsap.to(block, {
      scrollTrigger: {
        trigger: block,
        start: 'top 75%',
      },
      opacity: 1,
    })
  });

  // Red line animation
  const redLine = gsap.utils.toArray('.red-line');

  redLine.forEach((block, i) => {
    gsap.to(block, {
      scrollTrigger: {
        trigger: block,
        start: 'top 75%',
      },
      scaleX: 1,
      duration: 2,
    })
  });

  // H2 animation
  gsap.utils.toArray('.content-block h2, .video-block h2').forEach(headingTrigger => {

    const heading = headingTrigger;
    const headingSpan = headingTrigger.querySelectorAll('span');

    const move =  gsap.timeline({paused: true})
      .to(heading, 1.4, { x: 0, ease: Power1.easeInOut }, '+=0')
      .to(heading, 1.4, { opacity: 1, ease: Linear.easeNone }, '-=1.4')
      .to(headingSpan, 1, { color: '#fff', ease: Power1.easeInOut }, '+=0')
      .to(headingSpan, 1, { textStrokeColor: '#999', textStrokeWidth: 2, ease: Power1.easeInOut }, '-=1');

    ScrollTrigger.create({
      trigger: headingTrigger,
      start: 'top 75%',
      onEnter: () => move.play(),
    });
  });

  // gsap.to('#intro-block', {
  //   yPercent: -100,
  //   ease: "none",
  //   scrollTrigger: {
  //     trigger: "#explore",
  //     start: "top 75%",
  //     end: "bottom top",
  //     // absolute: true,
  //     scrub: true,
  //   }
  // })
});

// Video Player
function clickAll(e){
  if (e.type === 'click') return true;

  if (e.type === 'keypress') {
    const code = e.charCode || e.keyCode;

    if ((code === 32)|| (code === 13)){
      return true;
    }
  }

  return false;
};

// Pause videos when playing a video that has been played and paused already
function playFile() {
  $('video:not(#video_bg)').not(this).each(function () {
    $(this).get(0).pause();
  });
}

$('video:not(#video_bg)').on('click play', function() {
  playFile.call(this);
});

const exploreArticleEls = $('.article-wrapper');

// explore
$('#explore li').hover((e) => {
  const navItem = $(e.currentTarget);
  const state = Flip.getState('#explore li');

  ScrollTrigger.matchMedia({
    '(min-width: 768px)': () => {
      navItem.toggleClass('expanded');

      Flip.from(state, {
        ease: 'power4.inOut',
        nested: true,
      });
    },
  });
});

$('.article-block .btn-back').on('click', (e) => {
  e.preventDefault();

  exploreArticleEls.removeClass('active');
  $('#explore').show();

  gsap.to(window, {
    duration: 0.5,
    ease: 'power4.inOut',
    scrollTo: {
      y: '#explore',
      yOffset: 0,
    },
    onComplete: () => {
      document.location.hash = '';

      gsap.to(window, {
        duration: 0.4,
        ease: 'power4.inOut',
        scrollTo: '#explore',
      });
    },
  });
})

const showArticle = () => {
  const target = $(window.location.hash);
  const state = Flip.getState('.article-wrapper');

  exploreArticleEls.removeClass('active');

  if (!target.length) return;

  target.toggleClass('active');
  $('#hero, #intro-block, #explore').hide();

  Flip.from(state, {
    duration: 0.1,
    ease: 'power4.inOut',
    absoluteOnLeave: true,
    absolute: true,
    toggleClass: 'animating',
    // simple: true,
    // fade: true,
    onComplete: () => {
      gsap.to(window, {
        duration: 0.4,
        ease: 'power4.inOut',
        scrollTo: {
          y: target.get(0),
          yOffset: 0,
        },
        onComplete: () => {
          // Autoplay first video on page
          target.find('.video-block .video-js').each((i, el) => {
            const player = videojs(el);

            new Waypoint.Inview({
              element: el,
              entered: (direction) => {
                player.play();
              },
            });
          });
        },
      });
    },
  });
}

$(document).ready(showArticle);
$(window).on('hashchange', showArticle);
